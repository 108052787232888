<template>
  <base-layout>
    <ion-content id="subpage-detail" class="has-header has-subheader has-bottom-banner" >
      <h2 class="item">{{page.title}}</h2>
      <div v-if="page.id == '2ca1476f-7445-4bc7-9f8e-f0183aafc1e2' && !user">Please login to view this content</div>
      <div v-html="page.content" style="overflow:scroll" v-if="page.id != '2ca1476f-7445-4bc7-9f8e-f0183aafc1e2' || user"></div>
    </ion-content>
  </base-layout>
</template>

<script>
import { IonContent } from "@ionic/vue";
import { defineComponent } from "vue";
import {mapActions, mapGetters} from "vuex";
import moment from "moment";

export default defineComponent({
  name: "SubpageDetail",
  data() {
    return {
      page: {}
    };
  },
  components: {
    IonContent,
  },
  methods: {
    ...mapActions("subpages", [
      "getPage",
    ]),
    ...mapActions('googleanalytics', ['trackWithLabel']),

  },
  computed:{
    ...mapGetters('auth', ['user']),
  },
  
  async created() {
  },
  watch: {
    '$route': {
      immediate: true,
      async handler(route) {
        if (route.name == 'Subpage Detail') {
          this.moment = moment;
          this.page = await this.getPage(this.$route.params.id);
          this.trackWithLabel(this.page.title)

        }
      }
    }
  }
});
</script>
<style scoped>
#subpage-detail{
--padding-start:10px;
--padding-end:10px;
--padding-top:10px;
--padding-bottom:10px;
}
  
</style>
